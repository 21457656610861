import type { UserCallCapacity } from "./person.schema";
export interface SegmentRatio {
    segment: SegmentType | NonTarget; // 产品分级
    count: number; // 数量
    ratio: number; // 比例
}

export interface Product {
    productName: string; // 产品名
    itemList: SegmentRatio[]; // 分级列表
}
export interface ProductList {
    externalId: string; 
    id: string; 
    name: string; // 产品名
}

export interface HcpSegment {
    positionId: string;
    empName: string;
    totalTargetCount: number; // ⽬标客户总⼈数
    productList: Product[]; // 产品列表
    mcCycleStartDate: string;
}
export enum TargetApproveStatus {
    Y = "Approve",
    N = "N",
}
export interface TargetHcp {
    approveStatus: TargetApproveStatus;
    suggestion?: string;
    positionId: string;
    empName: string;
    isTba: boolean;
    isInterim: boolean;
    interimEmpName?: string;
    totalTargetCount: number;
    itemList: SegmentRatio[];
    nonTargetRoomHcpItem: SegmentRatio;
    callCapacityInfo: UserCallCapacity;
}

export interface ReviewRatio {
    productName: string; // 产品名称
    stCount: string | number; // ST数量
    stRatio: string | number; // ST比例
    tOneCount: string | number; // T1数量
    tOneRatio: string | number; // T1比例
    tTwoCount: string | number; // T2数量
    tTwoRatio: string | number; // T2比例
    tThreeCount: string | number; // T3数量
    tThreeRatio: string | number; // T3比例
    influencerCount: string | number; // Influencer数量
    influencerRatio: string | number; // Influencer比例
    total: string | number; // 目标客户总人数
}

export interface CurCycleAdjust {
    targetToNonTargetList: string[]; // 目标客户变为非目标客户
    nonTargetToTargetList: string[]; // 非目标客户变为目标客户
    mcCycleStartDate: string;
}

export interface NonTargetInfo {
    productName: string; // 产品名
    hcpSet: string[]; // 客户列表
}

export interface ReviewModule {
    labelName: string; // 标签组名称
    labelList: string[]; // 标签列表
}

export enum SegmentType {
    ST = "ST",
    T1 = "T1",
    T2 = "T2",
    T3 = "T3",
    Influencer = "Influencer",
}

export enum NonTarget {
    nonTarget = "非目标",
}

export interface SegmentParam {
    orderType?: OrderType;
    productId?: string;
    segment?: string;
    positionId?: string;
    hcoId?: string;
}

export enum OrderType {
    segment = "segment",
    default = "default",
}
