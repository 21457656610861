import {
    type ApproveParam,
    type CycleResult,
    type MessageItem,
    type OptionItem,
    type PaginationParam,
    type SuggestionParam,
    type Territory,
    type User,
    type UserSummary,
    type UserCallCapacity,
    CycleStageCode,
    Role,
    type ProductList,
} from "@schema";
import { CURRENT_USER, HttpService } from "../http/http.service";
import {
    CacheService,
    Inject,
    Provide,
    TrackAction,
    manualTracking,
} from "@common";
import { showNotify } from "@components";
import { CURRENT_AGENT ,TARGET_RANGE_LEVEL_ENUM} from "@constants";
import { showDialog } from "@nutui/nutui";
import router   from '@router';

export class PersonService {
    @Inject(HttpService)
    private http!: HttpService;
    private cache!: CacheService;
    private _currentUser?: User;
    private _suggestions!: OptionItem[];
    private _currentCycle?: CycleResult;
    private _watchTimer?: any;
    private _targetRangeLevelEnum?: string;
    private _readonlyStages = [
        CycleStageCode.SurveyCollectionEndDate,
        CycleStageCode.RecollectedEndDate,
    ];
    private _helpTextMap: Dict<string> = {};
    constructor() {
        this.cache = Provide(CacheService);
        const currentAgent = this.cache.get(CURRENT_AGENT);
        if (currentAgent) {
            showNotify({
                type: "success",
                msg:
                    '代理岗位已切换至"' +
                    (currentAgent?.name || "原岗位") +
                    '"',
            });
            this.cache.remove(CURRENT_AGENT);
        }
    }

    // 判断当前用户角色是否是只读状态
    private get isReadonlyUser(): boolean {
        return (
            this._currentUser?.isRolePlay ||
            (!this._currentUser?.canCollection &&
                this._currentUser?.roleCode === Role.MR)
        );
    }
    get currentUser(): User {
        if (!this._currentUser) {
            this._currentUser = this.cache.get(CURRENT_USER);
        }
        return this._currentUser!;
    }

    get currentTargetRangeLevelEnum(): any {
            this._targetRangeLevelEnum = this.cache.get(TARGET_RANGE_LEVEL_ENUM);
        return this._targetRangeLevelEnum;
    }
    /**
     * @description 获取周期数据
     */
    public async getCycle(): Promise<CycleResult> {
        if (!this._currentCycle) {
            this._currentCycle = await this.http.post(
                "/front/base/common/person/v1/getCycleInfo"
            );
        }
        return this._currentCycle as CycleResult;
    }

    /**
     * @description 获取消息列表
     * @param param 分页及查询过滤参数
     */
    public async getMsgList(
        param: PaginationParam<any>
    ): Promise<MessageItem[]> {
        return await this.http.post(
            "/front/base/common/person/v1/getMsgList",
            param
        );
    }


    /**
     * @description 获取新消息
     */
    public async getNewMsgCount():
        Promise<number> {
        return await this.http.post(
            "/front/base/common/person/v1/getNewMsgCount",
        );
    }

    /**
     * @description 获取个人信息
     */
    public async getCurrentSummary(): Promise<UserSummary> {
        return await this.http.post(
            "/front/base/common/person/v1/getPersonInfo"
        );
    }

    /**
     * @description 获取拜访工作量
     */
    public async getCurrentCallCapacity(): Promise<UserCallCapacity> {
        return await this.http.post(
            "/front/base/common/person/v1/getCurrentCallCapacity"
        );
    }

    /**
     * @description DM获取同区多代表信息
     */
    public async getTerritoryMultipleRepresentativesByDM(): Promise<
        Territory[]
    > {
        return await this.http.post(
            "/front/base/common/person/v1/getTerritoryMultipleRepInfo"
        );
    }

    /**
     * @description 切换代理岗位
     * @param agentId 代理岗位ID
     */
    public switchAgent(
        agentId?: string,
        agentName?: string,
        isAgent: boolean = true
    ): Promise<void> {
        return new Promise((resolve, reject) => {
            showDialog({
                title: "提示",
                content: `确认是否切换至${
                    agentId ? '代管岗位"' + agentName : '"原岗位'
                }"?`,
                okText: "确定",
                onOk: async () => {
                    //标记消息已读信息；location.replace无法触发组件onUnmounted生命周期；
                    if (router.currentRoute.value?.name === "message") {
                        await this.getMsgList({ pageNum: 0, pageSize: 1 ,data: {isViewComplete: true}})
                    }
                    const switchRes = await this.http.post(
                        "/front/base/common/person/v1/switchAgentPost",
                        {
                            agentPostId: agentId || "",
                            isViewModel: !isAgent,
                        }
                    );
                    manualTracking(TrackAction.SPM_SWITCH_AGENT, {
                        agent_id: agentId as string,
                        agent_name: (agentName as string) || "原岗位",
                        action_desc: `切换代理人: ${this.currentUser.roleCode} -> ${switchRes.agentRoleCode}`,
                    });
                    this.cache.set(CURRENT_USER, {
                        ...this.currentUser,
                        roleCode: switchRes.agentRoleCode,
                        canCollection: switchRes.canCollection,
                    });
                    delete this._currentUser;
                    this.cache.set(CURRENT_AGENT, {
                        key: agentId,
                        name: agentName,
                    });
                    resolve();
                    location.replace(import.meta.env.BASE_URL);
                },
                onCancel: reject,
            });
        });
    }

    /**
     * @description 审批
     * @param param 分页参数及审批参数
     */
    public approve(param: ApproveParam): Promise<void> {
        return new Promise((resolve, reject) => {
            showDialog({
                title: "提示",
                content: `审批之后将无法撤销, 是否确认通过审批? `,
                okText: "确定",
                overlayStyle: {
                    "z-index": 2050,
                },
                popStyle: {
                    "z-index": 2051,
                },
                onOk: async () => {
                    await this.http.post(
                        "/front/base/common/person/v1/approve",
                        param
                    );
                    manualTracking(TrackAction.SPM_ONE_CLICK_APPROVAL, {
                        action_type: "approve",
                        action_desc: "一键审批",
                    });
                    showNotify({
                        type: "success",
                        msg: "提交成功",
                    });
                    resolve();
                },
                onCancel: reject,
            });
        });
    }

    /**
     * @description 提交建议
     * @param param 分页参数及审批参数
     */
    public suggest(param: SuggestionParam): Promise<void> {
        return new Promise((resolve, reject) => {
            showDialog({
                title: "提示",
                content: `提交修改建议之后将无法撤销, 是否确认提交? `,
                okText: "确定",
                overlayStyle: {
                    "z-index": 2050,
                },
                popStyle: {
                    "z-index": 2051,
                },
                onOk: async () => {
                    await this.http.post(
                        "/front/base/common/person/v1/suggestion",
                        param
                    );
                    manualTracking(TrackAction.SPM_ONE_CLICK_APPROVAL, {
                        action_type: "suggest",
                        action_desc: "修改建议",
                    });
                    showNotify({
                        type: "success",
                        msg: "提交成功",
                    });
                    resolve();
                },
                onCancel: reject,
            });
        });
    }

    /**
     * @description 获得建议列表
     */
    public async getSuggestions(): Promise<OptionItem[]> {
        if (this._suggestions) {
            return this._suggestions;
        } else {
            this._suggestions = await this.http.post(
                "/front/base/common/mccp/v1/getSuggestionList"
            );
        }
        return this._suggestions;
    }

    /**
     * @description DM获取下属岗位员工
     */
    public async getChildPostEmp(): Promise<OptionItem[]> {
        return await this.http.post(
            "/front/base/common/person/v1/getChildPostEmp"
        );
    }

    /**
     * @description 获取是否是代理岗位
     */
    public async isAgentPosition(): Promise<boolean> {
        return await this.http.post("/front/base/common/person/v1/isAgentPost");
    }

    /**
     * @description 弹出提示文字
     * @param category 类别编码(Help_Info)
     * @param code 内容Code
     */
    public async popupSuggestiveText(
        category: string,
        code: string,
        notPop?: boolean
    ): Promise<string | void> {
        if (!this._helpTextMap[category + code]) {
            const res = await this.http.post(
                "/front/base/common/person/v1/getConfigInfo",
                {
                    category,
                    code,
                }
            );
            this._helpTextMap[category + code] = res.name;
        }
        this._helpTextMap[category + code] &&
            !notPop &&
            showDialog({
                title: "温馨提示",
                content: this._helpTextMap[category + code],
                textAlign: "left",
                noCancelBtn: true,
                popClass: "popClass",
            });
        return this._helpTextMap[category + code];
    }

    /**
     * @description 判断当前是否是只读模式
     */
    public async isReadonly(): Promise<boolean> {
        if (this.isReadonlyUser) {
            return true;
        } else {
            const list = (await this.getCycle()).cycleInfoDateValueList;
            const cycleStageDateTime = [...list]
                .reverse()
                .find((e) =>
                    this._readonlyStages.includes(e.cycleStageCode as any)
                )?.cycleStageDateTime;
            const deadline = cycleStageDateTime && new Date(cycleStageDateTime);
            const isOverDeadline = deadline && Date.now() >= deadline.getTime();
            !isOverDeadline && deadline && this.initWatchDeadLine(deadline);
            return !!isOverDeadline;
        }
    }

        /**
     * @description 判断预计目标医生级别禁用btn
     */
    public async isReadonlyLevel(id: string): Promise<boolean> {
        if (id !== "btn_add_doctor" && id !== "btn_batch_delete") {
            return false;
        }
         //删除-过低时 或者 新增-过高时 禁用btn
        if (id === "btn_add_doctor" && this.currentTargetRangeLevelEnum === "HighForbidden") {
            return true;
        } else if (id === "btn_batch_delete" && this.currentTargetRangeLevelEnum === "LowForbidden") {
                return true;
            } else {
                return false;
            }

    }

    /**
     * @description 初始化监听是否到了截止时间， 若当前时间距离截止时间小于24小时则开始监听，运行时过了截止时间， 弹出提示并且重载页面
     * @param deadline 截止时间
     */
    private initWatchDeadLine(deadline: Date): void {
        clearInterval(this._watchTimer);
        const timeGap = deadline.getTime() - Date.now();
        if (timeGap > 0 && timeGap < 10 ** 3 * 60 * 60 * 24) {
            this._watchTimer = setInterval(async () => {
                if (deadline.getTime() < Date.now()) {
                    showNotify({
                        msg: [...(await this.getCycle()).cycleInfoDateValueList]
                            .reverse()
                            .find((e) =>
                                this._readonlyStages.includes(
                                    e.cycleStageCode as any
                                )
                            )?.cycleStageName,
                    });
                    clearInterval(this._watchTimer);
                    setTimeout(() => location.reload(), 3000);
                }
            }, 3 * 10 ** 3 + 300);
        }
    }

    /**
     * @description 获取产品信息
     */
    public async getProductListByExtIdList(
        param: string[]
    ): Promise<ProductList[]>{
        return await this.http.post(
            "/front/base/common/product/v1/getProductListByExtIdList",
            param
        );
    }
}
